import React, { useEffect, useRef, useState } from "react";
import HostPageADC from "../ArrowDescriptionComponents/HostPageADC";
import SoulPageADC from "../ArrowDescriptionComponents/SoulPageADC";
import BackgroundVideo from "./BackgroundVideo";
import Header from "./Header";
import Lottie from "react-lottie";
import Insta from "../Assets/Icons/Insta.json";
import FB from "../Assets/Icons/FB.json";
import LinkedIn from "../Assets/Icons/Linkedin.json";
import {
  PHONE_SCREEN_BLOCKER_HEIGHT,
  PHONE_SCREEN_BORDER_RADIUS,
  PHONE_SCREEN_STICKY_BOTTOM_POSITION,
} from "../Util/dimensionConstants";

import "../Styles/cube-word-styles.css";
import { RoughNotation } from "react-rough-notation";
import HeaderDrawer from "./HeaderDrawer";
import useIsInViewPort from "../Util/useIsInViewPort";
import ComingSoonModal from "./ComingSoonModal";
import { useDocumentTitle } from "../Hooks/changeTitleHook";
import BottomSection from "./Home/BottomSection/BottomSection";
import "../Styles/home.css";
import YouTubeFloatingButton from "./YouTubeFloatingButton";
import DownloadAppButton from "./DownloadAppButton";
const PhoneScreenContainer = React.lazy(() =>
  import("../PhoneScreenContainer")
);

const defaultOptions = {
  loop: true,
  autoplay: true,
  // here is where we will declare lottie animation
  // "animation" is what we imported before animationData: animation,

  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const TextOverVideoComp = (setOpenModal) => {
  return (
    <div
      style={{
        top: "20%",
        position: "relative",
        overflowWrap: "anywhere",
        width: "100%",
      }}
    >
      <div className="social-buttons-sticky-box">
        <div
          onClick={() =>
            window.open(
              "https://www.linkedin.com/company/huming-app/",
              "_blank"
            )
          }
        >
          <Lottie
            options={{ ...defaultOptions, animationData: LinkedIn }}
            height={24}
            width={24}
          />
        </div>
        <div
          onClick={() =>
            window.open("https://www.facebook.com/huming.official", "_blank")
          }
        >
          <Lottie
            options={{ ...defaultOptions, animationData: FB }}
            height={24}
            width={24}
          />
        </div>
        <div
          onClick={() =>
            window.open("https://www.instagram.com/huming.app/", "_blank")
          }
        >
          <Lottie
            options={{ ...defaultOptions, animationData: Insta }}
            height={24}
            width={24}
          />
        </div>
      </div>

      <h1
        style={{
          fontFamily: "FuturaLondon",
          fontSize: "5vmax",
          color: "white",
          fontWeight: "300",
          textAlign: "center",
        }}
      >
        {`Tired of fighting over photos you`}
      </h1>
      <h1
        style={{
          fontFamily: "FuturaLondon",
          fontSize: "5vmax",
          color: "white",
          fontWeight: "300",
          textAlign: "center",
        }}
      >{`Didn't get after the`}</h1>

      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        <div className="cube">
          <div className="cube-face face1">Vacation</div>
          <div className="cube-face face2">Wedding</div>
          <div className="cube-face face3">Trip</div>
          <div className="cube-face face4">Event</div>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          width: "100%",
          position: "sticky",
          top: 0,
        }}
      >
        <div
          style={{
            marginLeft: "10%",
            marginRight: "10%",
            display: "flex",
            justifyContent: "space-between",
            maxWidth: 500,
          }}
        >
          <DownloadAppButton
            firstText={"GET IT ON"}
            secondText={"Google Play"}
            hrefLink={
              "https://play.google.com/store/apps/details?id=com.huming"
            }
            svg={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="icon"
                viewBox="0 0 512 512"
              >
                <path d="M99.617 8.057a50.191 50.191 0 00-38.815-6.713l230.932 230.933 74.846-74.846L99.617 8.057zM32.139 20.116c-6.441 8.563-10.148 19.077-10.148 30.199v411.358c0 11.123 3.708 21.636 10.148 30.199l235.877-235.877L32.139 20.116zM464.261 212.087l-67.266-37.637-81.544 81.544 81.548 81.548 67.273-37.64c16.117-9.03 25.738-25.442 25.738-43.908s-9.621-34.877-25.749-43.907zM291.733 279.711L60.815 510.629c3.786.891 7.639 1.371 11.492 1.371a50.275 50.275 0 0027.31-8.07l266.965-149.372-74.849-74.847z"></path>
              </svg>
            }
          />
          <DownloadAppButton
            firstText={"Download from"}
            secondText={"App store"}
            hrefLink={"https://apps.apple.com/us/app/huming/id6462796387"}
            svg={
              <svg
                fill="currentcolor"
                viewBox="-52.01 0 560.035 560.035"
                xmlns="http://www.w3.org/2000/svg"
                stroke="#ffffff"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path d="M380.844 297.529c.787 84.752 74.349 112.955 75.164 113.314-.622 1.988-11.754 40.191-38.756 79.652-23.343 34.117-47.568 68.107-85.731 68.811-37.499.691-49.557-22.236-92.429-22.236-42.859 0-56.256 21.533-91.753 22.928-36.837 1.395-64.889-36.891-88.424-70.883-48.093-69.53-84.846-196.475-35.496-282.165 24.516-42.554 68.328-69.501 115.882-70.192 36.173-.69 70.315 24.336 92.429 24.336 22.1 0 63.59-30.096 107.208-25.676 18.26.76 69.517 7.376 102.429 55.552-2.652 1.644-61.159 35.704-60.523 106.559M310.369 89.418C329.926 65.745 343.089 32.79 339.498 0 311.308 1.133 277.22 18.785 257 42.445c-18.121 20.952-33.991 54.487-29.709 86.628 31.421 2.431 63.52-15.967 83.078-39.655"></path>
                </g>
              </svg>
            }
          />
        </div>
      </div>
    </div>
  );
};
export default function Home() {
  const [phoneScreenBottom1, setPhoneScreenBottom1] = useState(0);
  const [phoneScreenBottom2, setPhoneScreenBottom2] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [position, setPosition] = useState(0);
  useEffect(() => {}, [phoneScreenBottom1]);
  useDocumentTitle("Taking care of your memories");
  document
    .querySelector('meta[name="description"]')
    .setAttribute(
      "content",
      "Taking care of your memories by doing the hard part of sharing. You Click and forget about sharing them with group, we are here now..."
    );
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: false });
    // setTimeFunc(800);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleScroll = (event) => {
    const position = window.pageYOffset;
    setPosition(position);
  };

  const humingRef = useRef(null);
  const isHumingInViewport = useIsInViewPort(humingRef);

  const automateRef = useRef(null);
  const isAutomateInViewport = useIsInViewPort(automateRef);

  return (
    <div>
      {/* <style>{animate}</style> */}
      <Header inFocus={0} strokeColor={"white"} screenName={"Home"} />
      <HeaderDrawer inFocus={0} screenName={"Home"} />
      <BackgroundVideo
        srcPath={require("../Assets/Videos/a.mp4")}
        thumbnail={require("../Assets/Videos/a.webp")}
        childComponent={TextOverVideoComp}
        height={"100%"}
        setOpenModal={setOpenModal}
      />
      <div
        style={{
          width: "100%",
          height: "100vh",
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: "-5px",
        }}
      >
        <h2
          style={{
            fontFamily: "FuturaLondon",
            fontSize: "5vmax",
            color: "Black",
            fontWeight: "300",
            textAlign: "center",
            backgroundColor: "transparent",
            paddingTop: 10,
            marginBottom: 20,
          }}
        >
          {`Don't Worry We Have Got You Covered,`}
        </h2>
        <h2
          style={{
            fontFamily: "FuturaLondon",
            fontSize: "5vmax",
            color: "Black",
            fontWeight: "300",
            textAlign: "center",
            backgroundColor: "transparent",
            marginBottom: 50,
          }}
        >
          {`We Bring You  `}
          <span
            ref={humingRef}
            style={{
              fontFamily: "FuturaLondon",
              fontSize: "5vmax",
              color: "Black",
              fontWeight: "900",
              textAlign: "center",
              backgroundColor: "transparent",
            }}
          >
            <RoughNotation
              show={isHumingInViewport}
              strokeWidth={3}
              color="green"
            >
              HUMING
            </RoughNotation>
          </span>
        </h2>
        <div
          style={{
            fontFamily: "FuturaLondon",
            fontSize: "3.5vmax",
            color: "Black",
            fontWeight: "300",
            textAlign: "center",
            backgroundColor: "transparent",
            marginTop: 0,
          }}
        >
          {`A platform where you can `}
          <RoughNotation
            show={isAutomateInViewport}
            color="yellow"
            multiline
            type="highlight"
          >
            <span ref={automateRef}>
              <strong>
                {`automate the sharing of your beautiful memories...`}{" "}
              </strong>
            </span>
          </RoughNotation>
          {` We will do the hard part of sharing, you do the best part, Enjoying. And
          remember don't fight...`}
          {/* 😉 */}
        </div>
        <h3
          style={{
            fontFamily: "FuturaLondon",
            fontSize: "3.4vmax",
            color: "Black",
            fontWeight: "300",
            textAlign: "center",
            backgroundColor: "transparent",
            marginTop: 0,
          }}
        >
          {`See yaaa!!! Down Stairs...`}
        </h3>
      </div>
      <div
        style={{
          position: "relative",
          backgroundColor: "rgb(254 207 207)",
          // height: "100%",
          // marginTop: "-1px",
          width: "100%",
        }}
      >
        <React.Suspense fallback={<span>Loading...</span>}>
          <PhoneScreenContainer
            // bgColor={'black'}
            bgImage={true}
            bgColor={"rgb(255 237 255)"}
            srcUrl={require("../Assets/Images/SoulPage.webp")}
            ArrowComponent={SoulPageADC}
            position={position}
            setPhoneScreenBottom={setPhoneScreenBottom1}
            phoneScreenBottom={phoneScreenBottom1}
          />
        </React.Suspense>
        <React.Suspense fallback={<span>Loading...</span>}>
          <PhoneScreenContainer
            bgColor={"transparent"}
            srcUrl={require("../Assets/Images/HostPage.webp")}
            ArrowComponent={HostPageADC}
            position={position}
            setPhoneScreenBottom={setPhoneScreenBottom2}
            phoneScreenBottom={phoneScreenBottom2}
          />
        </React.Suspense>
        <React.Suspense fallback={<span>Loading...</span>}>
          <PhoneScreenContainer
            bgColor={"transparent"}
            srcUrl={require("../Assets/Images/Gallery.webp")}
            ArrowComponent={() => {}}
            // ArrowComponent={<></>}
          />
        </React.Suspense>
        <React.Suspense fallback={<span>Loading...</span>}>
          <PhoneScreenContainer
            bgColor={"transparent"}
            srcUrl={require("../Assets/Images/ScheduleHosting.webp")}
            ArrowComponent={() => {}}
            // ArrowComponent={<></>}
          />
        </React.Suspense>
      </div>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: PHONE_SCREEN_BLOCKER_HEIGHT + 1,
          backgroundColor: "rgb(255, 255, 255)",
          marginTop: "-1px",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "end",
          position: "sticky",
          bottom: 0,
          zIndex: 4,
        }}
      >
        <div
          style={{
            height: PHONE_SCREEN_BLOCKER_HEIGHT + PHONE_SCREEN_BORDER_RADIUS,
            flex: 1,
            marginRight: "-1px",
            background:
              phoneScreenBottom1 <= PHONE_SCREEN_STICKY_BOTTOM_POSITION
                ? `-webkit-radial-gradient(100% 0, circle, rgb(255, 237, 255, 0) 20px,rgb(0, 0, 0, 1) 0px)`
                : "transparent",
          }}
        >
          <div
            style={{
              backgroundImage:
                phoneScreenBottom1 <= PHONE_SCREEN_STICKY_BOTTOM_POSITION
                  ? `url(${require("../Assets/Images/photocolage.webp")})`
                  : "none",
              backgroundSize: "650px",
              height: PHONE_SCREEN_BLOCKER_HEIGHT,
              marginTop: 20,
            }}
          />
        </div>
        <div
          style={{
            height: PHONE_SCREEN_BLOCKER_HEIGHT,
            width: 212,
            backgroundImage:
              phoneScreenBottom1 <= PHONE_SCREEN_STICKY_BOTTOM_POSITION
                ? `url(${require("../Assets/Images/photocolage.webp")})`
                : "none",
            backgroundSize: "650px",
          }}
        />
        <div
          style={{
            height: PHONE_SCREEN_BLOCKER_HEIGHT + PHONE_SCREEN_BORDER_RADIUS,
            flex: 1,
            marginLeft: "-1px",
            background:
              phoneScreenBottom1 <= PHONE_SCREEN_STICKY_BOTTOM_POSITION
                ? `-webkit-radial-gradient(0 0, circle, rgb(255, 237, 255, 0) 20px,rgb(0, 0, 0, 1) 0px)`
                : "transparent",
          }}
        >
          <div
            style={{
              backgroundImage:
                phoneScreenBottom1 <= PHONE_SCREEN_STICKY_BOTTOM_POSITION
                  ? `url(${require("../Assets/Images/photocolage.webp")})`
                  : "none",
              backgroundSize: "650px",
              height: PHONE_SCREEN_BLOCKER_HEIGHT,
              marginTop: 20,
            }}
          />
        </div>
      </div>
      <BottomSection />
      <ComingSoonModal
        handleClose={() => {
          setOpenModal(false);
        }}
        open={openModal}
      />
      <YouTubeFloatingButton />
    </div>
  );
}
