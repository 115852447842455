import React from "react";
import "../../../Styles//CCESBlocks.css";
import Lottie from "react-lottie";

const CCESBlocks = ({
  icon,
  mainText,
  bracketText,
  lottieFile,
  textInTheBack,
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    // here is where we will declare lottie animation
    // "animation" is what we imported before animationData: animation,

    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="card">
      <div className="content">
        <div className="back">
          <div className="back-content">
            {lottieFile ? (
              <Lottie
                options={{ ...defaultOptions, animationData: lottieFile }}
                height={100}
                width={100}
              />
            ) : (
              <img alt="" src={icon} style={{ width: 50, height: 50 }}></img>
            )}
            <text
              style={{
                fontSize: 22,
                fontFamily: "FuturaLondon",
                fontWeight: "900",
              }}
            >
              {mainText}
            </text>
            <text
              style={{
                fontSize: 18,
                fontFamily: "FuturaLondon",
                fontWeight: "900",
                marginBottom: 10,
              }}
            >
              {`(${bracketText})`}
            </text>
          </div>
        </div>
        <div className="front">
          <div className="img">
            <div className="circle"></div>
            <div className="circle" id="right"></div>
            <div className="circle" id="bottom"></div>
          </div>

          <div className="front-content">
            <small className="badge">{mainText}</small>
            <div className="description">
              <div className="title">
                <p className="title">
                  <strong>{textInTheBack}</strong>
                </p>
                <svg
                  fillRule="nonzero"
                  height="15px"
                  width="15px"
                  viewBox="0 0 256 256"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    style={{ mixBlendMode: "normal" }}
                    textAnchor="none"
                    fontSize="none"
                    fontWeight="none"
                    fontFamily="none"
                    strokeDashoffset="0"
                    strokeDasharray=""
                    strokeMiterlimit="10"
                    strokeLinejoin="miter"
                    strokeLinecap="butt"
                    strokeWidth="1"
                    stroke="none"
                    fillRule="nonzero"
                    fill="#20c997"
                  >
                    <g transform="scale(8,8)">
                      <path d="M25 27l-9-6.75-9 6.75v-23h18z"></path>
                    </g>
                  </g>
                </svg>
              </div>
              <p className="card-footer"> &nbsp; &nbsp;</p>
              {/* <p className="card-footer">30 Mins &nbsp; | &nbsp; 1 Serving</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CCESBlocks;
