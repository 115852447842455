import React from "react";
import BackgroundVideo from "./BackgroundVideo";
import Header from "./Header";
import { FrameGrid } from "@egjs/react-grid";
import "../Styles/base-styles.css";
import "../Styles/ticker-styles.css";
import "../Styles/future-styles.css";
import LandscapeTiles from "./Future/LandscapeTiles";
import PortraitTiles from "./Future/PortraitTiles";
import HeaderDrawer from "./HeaderDrawer";
import { useDocumentTitle } from "../Hooks/changeTitleHook";

const TextOverVideoComp = () => {
  return (
    <div
      style={{
        bottom: 0,
        left: 0,
        position: "absolute",
        width: "100%",
        height: "100%",
        background: "linear-gradient(rgb(0,0,0,0), rgb(0,0,0,1))",
      }}
    >
      <div className="futurePara">
        We have a lot of new features lined up in the pipeline being developed
        right now. We are going to enhance scheduled hosting. Powering your
        experience with safe and secure AI technology. you will be having more
        smoother user Interface. We do understand that Huming needs more
        features and capabilities to make your precious memories more organized,
        secured, accessible. And we are working on it quite aggressively.
      </div>
      <div
        style={{
          bottom: "5%",
          left: 30,
          position: "absolute",
          zIndex: 4,
        }}
      >
        <div className="futureHeading">WHATS NEXT</div>
      </div>
    </div>
  );
};

export default function Future() {
  useDocumentTitle(
    "Future of huming, world's most convenient photo sharing app"
  );
  document
    .querySelector('meta[name="description"]')
    .setAttribute(
      "content",
      "We know the potential of artificial intelligence that it can provide the best user experience, and we are working towards it aggressively, Adding more security measures in the huming product, multiple user feature, dissecting the memories in more catagories so that everything will be more organized, and many more features will be added."
    );

  return (
    <div
      style={{
        background:
          "radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%)",
      }}
    >
      <Header inFocus={1} strokeColor={"white"} screenName={"Future"} />
      <HeaderDrawer inFocus={1} screenName={"Future"} />
      <BackgroundVideo
        srcPath={require("../Assets/Videos/Tech.mp4")}
        thumbnail={require("../Assets/Videos/Tech.webp")}
        childComponent={TextOverVideoComp}
        height={"100vh"}
      />
      <div
        style={{
          width: "100%",
          zIndex: 4,
        }}
      >
        <div className="ticker-container">
          <div className="ticker-wrapper">
            <div className="ticker-transition">
              <div className="ticker-item ticker-fontSize">
                <strong>
                  Exciting Features coming for you and your experience at
                  HUMING...
                </strong>

                {/* 😉 */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          zIndex: 4,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="tiles1">
          <FrameGrid
            className="container"
            gap={40}
            defaultDirection={"end"}
            align={"justify"}
            frame={[
              [1, 1, 2, 2],
              [3, 3, 2, 2],
              [4, 4, 4, 5],
              [4, 4, 4, 5],
            ]}
            rectSize={0}
            useFrameFill={true}
          >
            <LandscapeTiles />
          </FrameGrid>
        </div>
        <div className="tiles2">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <PortraitTiles />
          </div>
        </div>
        <div
          style={{
            // background:
            //   'linear-gradient(200deg, rgb(248 248 248) 0%, rgb(100 5 88) 100%)100%)',
            background:
              "linear-gradient(200deg,rgba(238,174,202,1) 0%, rgb(50 50 100) 100%)",
            padding: 20,
            letterSpacing: 2,
            borderRadius: 86,
          }}
          className="paraStyle"
        >
          Our main emphasis now is to grow out of a minimum viable product and
          create a very stable and one stop solution for not just sharing your
          memories but for your MEMORIES.Innovation is our driving force that
          will never change and that how we were born in the First place...
        </div>
      </div>
    </div>
  );
}

//We will be focussing on three main sections of the platform which are Security and privacy, new features and more smooth user interface.2
