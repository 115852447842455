import React from "react";

export default function BackgroundVideo({
  srcPath,
  childComponent,
  height,
  setOpenModal,
  thumbnail,
}) {
  const aspectRatio = window.innerWidth / window.innerHeight;
  const videoStyle = { objectFit: "cover", width: "100%" };
  if (aspectRatio > 1) {
    videoStyle["height"] = height;
  }
  return (
    <>
      <video
        height={aspectRatio < 1 ? window.innerHeight : "100%"}
        poster={thumbnail}
        style={videoStyle}
        autoPlay
        loop
        muted
      >
        <source src={srcPath} type="video/mp4" />
      </video>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          backgroundColor: "transparent",
          backdropFilter: "blur(7.5)",
          zIndex: 6,
          top: 0,
        }}
      >
        {childComponent(setOpenModal)}
      </div>
    </>
  );
}
