import React from "react";
import "./../../../Styles/CCESBlocks.css";
import "../../../Styles/heading.css";
const Heading = () => {
  return (
    <>
      <div className="cces-start" style={{}}>
        We provide you a complete CCES experience throughout.
      </div>
      <div className="cces-question-text">What's CCES?</div>
    </>
  );
};

export default Heading;
