import React, { useEffect, useMemo, useState } from "react";

const useIsInViewPort = (componentRef) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    observer.observe(componentRef.current);
    return () => {
      observer.disconnect();
    };
  }, [componentRef, observer]);

  return isIntersecting;
};

export default useIsInViewPort;
