import React from "react";
import "../Styles/playstore-button.css";
export default function DownloadAppButton({
  firstText,
  secondText,
  svg,
  hrefLink,
}) {
  return (
    /* From Uiverse.io by Yaya12085 */
    <a className="download-app-button" href={hrefLink}>
      <span className="icon">{svg}</span>

      <span className="texts">
        <span className="text-1">{firstText}</span>
        <span className="text-2">{secondText}</span>
      </span>
    </a>
  );
}
