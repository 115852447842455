import React, { useEffect, useState } from "react";
import { PHONE_SCREEN_STICKY_BOTTOM_POSITION } from "../Util/dimensionConstants";

export default function SoulPageADC({ position, phoneScreenBottom }) {
  const [opacity, setOpacity] = useState(1);
  const [stickyPosition, setStickyPosition] = useState(null);

  useEffect(() => {
    if (
      phoneScreenBottom <= PHONE_SCREEN_STICKY_BOTTOM_POSITION &&
      !stickyPosition
    ) {
      setStickyPosition(position);
    }
    if (stickyPosition) {
      setOpacity(1 - (position - stickyPosition) / window.innerHeight);
    }
  }, [position]);

  useEffect(() => {}, [opacity]);

  const animationStyle = ` @keyframes fadeAway {
        from { opacity: 1; }
        to { opacity: ${opacity}; }
      }
        .soulPageText {
            font-size: 20px;
            color: black;
            width: 20px;
            height: 20px;
            transform: translateY(-55px);
          opacity:${opacity};
    
        }
    
        .animateText{
          animation-name: fadeAway;
          animation-duration: 1s;
          animation-fill-mode: forwards;
          animation-iteration-count: 1;
    
        }
    
        .soulPageArrow {
            object-fit: contain;
            transform: rotate(50deg);
          opacity:${opacity}
        }
      }`;
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row-reverse",
        position: "absolute",
        right: "50%",
        top: PHONE_SCREEN_STICKY_BOTTOM_POSITION - 100,
        zIndex: 4,
        paddingRight: 30,
        // backgroundColor: "blue",
      }}
    >
      <style>{animationStyle}</style>
      <img
        style={{
          width: 100,
        }}
        src={require("../Assets/CurvedArrowIcons/arrow.webp")}
        className={"soulPageArrow"}
        alt="Arrow Indicating huming feature"
        title="This arrow indicates the entry point button which start your hosting process in which the photos and videos will be shared automatically"
        loading="lazy"
      />
      <p
        style={{
          marginRight: "50%",
        }}
        className={"soulPageText"}
      >
        Add new Memory
      </p>
    </div>
  );
}
