import React from "react";
import Heading from "./Heading";
import CCESBlocks from "./CCESBlocks";
import selfie from "../../../Assets/SVG/5502893.svg";
import create from "../../../Assets/Icons/create.json";
import capture from "../../../Assets/Icons/capture.json";
import enjoy from "../../../Assets/Icons/Enjoy.json";
import secure from "../../../Assets/Icons/Secure.json";
import privateLott from "../../../Assets/Icons/Private.json";

import ClosingPoints from "./ClosingPoints";
import "../../../Styles/gradient-animation.css";
import "../../../Styles/bottom-section.css";
const BottomSection = () => {
  console.log(window.innerHeight);

  return (
    <div className="background-gradient">
      {/* <div className="blur"> */}
      <div className="title-text">
        Still confused about why to choose HUMING?
        <br />
      </div>
      <Heading />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div className="blocks-parent">
          <CCESBlocks
            icon={selfie}
            mainText={"Create"}
            lottieFile={create}
            bracketText={`C`}
            textInTheBack={`Create an event in Huming. (Less than 4 clicks)`}
          />
          <CCESBlocks
            icon={selfie}
            mainText={"Capture"}
            lottieFile={capture}
            bracketText={`C`}
            textInTheBack={
              "Capture photos and videos in the camera which you love."
            }
          />
          <CCESBlocks
            icon={selfie}
            mainText={"Enjoy"}
            lottieFile={enjoy}
            bracketText={`E`}
            textInTheBack={
              "Enjoy the moment without the worry of sharing the photos."
            }
          />
          <CCESBlocks
            icon={selfie}
            mainText={"Secure"}
            lottieFile={secure}
            bracketText={`S`}
            textInTheBack={
              "We collaborated with Google Drive to keep everything secure."
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            height: 20,
          }}
        ></div>
        <div className="plus-p">
          <CCESBlocks
            icon={selfie}
            mainText={"+Private Mode"}
            lottieFile={privateLott}
            bracketText={`+P`}
            textInTheBack={
              "We do understand that some moments are meant to be private."
            }
          />
        </div>
      </div>

      <ClosingPoints />
      {/* </div> */}
    </div>
  );
};

export default BottomSection;
