import React, { useState } from "react";
import { WavyLink } from "react-wavy-transitions";
import { RoughNotation } from "react-rough-notation";
import "../Styles/header-styles.css";
import HumingLogo from "../Assets/SVG/HumingLogo.svg";
import HumingWhiteLogo from "../Assets/SVG/HumingWhiteLogo.svg";
export default function Header({ inFocus, strokeColor, screenName }) {
  const [isMouseOnHome, setIsMouseOnHome] = useState(false);
  const [isMouseOnFuture, setIsMouseOnFuture] = useState(false);
  const [isMouseOnVision, setIsMouseOnVision] = useState(false);

  return (
    <div
      style={{
        marginTop: 20,
        width: "100%",
        zIndex: 7,
        position: "absolute",
        top: 0,
        alignItems: "center",
      }}
      className="header-base"
    >
      <div
        style={{ marginLeft: 30 }}
        onMouseEnter={() => {
          if (inFocus === 0) {
            return;
          } else {
            setIsMouseOnHome(true);
          }
        }}
        onMouseLeave={() => {
          if (inFocus === 0) {
            return;
          } else {
            setIsMouseOnHome(false);
          }
        }}
      >
        <RoughNotation
          type="circle"
          show={inFocus === 0 || isMouseOnHome}
          iterations={1}
          padding={20}
          animate={!(inFocus === 0)}
          animationDuration={500}
          color={strokeColor}
        >
          <WavyLink
            to={"/"}
            children={
              <div
                className={`${
                  screenName === "ContactUs"
                    ? "black-header-font"
                    : "white-header-font"
                }`}
              >
                Home
              </div>
            }
            color="#2f2c52"
          />
        </RoughNotation>
      </div>
      <div
        style={{ marginLeft: 70 }}
        onMouseEnter={() => {
          if (inFocus === 1) {
            return;
          } else {
            setIsMouseOnFuture(true);
          }
        }}
        onMouseLeave={() => {
          if (inFocus === 1) {
            return;
          } else {
            setIsMouseOnFuture(false);
          }
        }}
      >
        <RoughNotation
          type="circle"
          show={inFocus === 1 || isMouseOnFuture}
          iterations={1}
          padding={20}
          animate={!(inFocus === 1)}
          animationDuration={500}
          color={strokeColor}
        >
          <WavyLink
            to={"/future"}
            children={
              <div
                className={`${
                  screenName === "ContactUs"
                    ? "black-header-font"
                    : "white-header-font"
                }`}
              >
                Future
              </div>
            }
            color="#2f2c52"
          />
        </RoughNotation>
      </div>
      <div
        style={{ marginLeft: 35 }}
        onMouseEnter={() => {
          if (inFocus === 2) {
            return;
          } else {
            setIsMouseOnVision(true);
          }
        }}
        onMouseLeave={() => {
          if (inFocus === 2) {
            return;
          } else {
            setIsMouseOnVision(false);
          }
        }}
      >
        <RoughNotation
          type="circle"
          show={inFocus === 2 || isMouseOnVision}
          iterations={1}
          padding={20}
          animate={!(inFocus === 2)}
          animationDuration={500}
          color={strokeColor}
        >
          <WavyLink
            to={"/about"}
            children={
              <div
                className={`${
                  screenName === "ContactUs"
                    ? "black-header-font"
                    : "white-header-font"
                }`}
              >
                Who are we
              </div>
            }
            color="#2f2c52"
          />
        </RoughNotation>
      </div>

      <WavyLink
        to={"/"}
        children={
          <img
            src={screenName === "Future" ? HumingWhiteLogo : HumingLogo}
            alt="Huming's black full word Logo"
            title="Represents the subtle and convenient User experience."
            style={{
              width: "58%",
              height: "50%",
            }}
            loading="lazy"
          />
        }
        color="#2f2c52"
      />
    </div>
  );
}
