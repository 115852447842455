import React from "react";

const PortraitTiles = () => {
  return (
    <>
      <div
        style={{
          backgroundColor: "#cf9191",
          display: "flex",
          borderRadius: 35,
          marginBottom: 20,
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        <h2 className="verticalHeading">SECURITY</h2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            paddingTop: 30,
          }}
        >
          <img
            src={require("../../Assets/Images/laptop-lock.webp")}
            alt="Security of Huming"
            title="At Huming we take Security of user very seriously that why we have achieved not sharing a single pixel of there photos and videos to the backend server which is managed by us and we let this secure task get handled by google's drive"
            style={{
              width: "70%",
              zIndex: 4,
              alignSelf: "center",
              borderRadius: 35,
            }}
            loading="lazy"
          />
          <p className="paraStyle">
            We know that the security which is implemented is enough, but we
            want to be EXTRA CAUTIOUS
          </p>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#6281b5",
          display: "flex",
          justifyContent: "center",
          borderRadius: 35,
          marginBottom: 20,
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2 className="horizontalHeading">Artificial Intelligence</h2>
          <img
            src={require("../../Assets/Images/AI.webp")}
            alt="Huming's future with AI "
            title="We understand the scope and capabilities of AI that how much it can make our user's life easier. So we are very keen in integrating AI with Huming"
            style={{
              width: "96%",
              zIndex: 4,
              borderRadius: 35,
            }}
            loading="lazy"
          />
          <p className="paraStyle">
            We believe that using Artificial Intelligence in balanced manner can
            make our life easier and more convenient. So We will be using AI to
            make our platform much more convenient for you in terms of stopping
            unwanted images to share or more customized clusters to share so
            that only those photos will be shared with them which are supposed
            to...
          </p>
        </div>
      </div>
      <div
        style={{
          backgroundColor: "#F5C8B7",
          borderRadius: 35,
          display: "flex",
          justifyContent: "space-around",
          marginBottom: 20,
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <img
            src={require("../../Assets/Images/Web-App.webp")}
            alt="Huming's web application"
            title="We understand that the experience of viewing the photos and videos of there precious memories should not be bounded on small screen so we will be creating an app through which user can enjoy there memories in big screen..."
            style={{
              width: "70%",
              marginTop: 20,
              zIndex: 4,
              borderRadius: 35,
              alignSelf: "center",
            }}
            loading="lazy"
          />
          <p className="paraStyle">
            This will be a vital Wing of HUMING which will provide flexibility
            to the users to organize and enjoy their memories on lets say a Big
            screen...
            {/* (😅😂) */}
          </p>
        </div>
        <h2 className="verticalHeading">WEB-APP</h2>
      </div>
      <div
        style={{
          backgroundColor: "#B6A7B3",
          borderRadius: 35,
          display: "flex",
          flexDirection: "row",
          marginBottom: 20,
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        <img
          src={require("../../Assets/Images/Scheduled.webp")}
          alt="Scheduled sharing of memories in Huming"
          title="At huming we understand that there are people with schedule in there life where they love to pre-plan everything so there is scheduled hosting which will let you schedule the memory before-hand"
          style={{
            width: "50%",
            margin: 10,
            zIndex: 4,
            alignSelf: "center",
            borderRadius: 35,
          }}
          loading="lazy"
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h2 className="horizontalHeading">Event-Wise Scheduled Hosting</h2>
          <p className="paraStyle">
            You can schedule your events or trips already in HUMING but can you
            create sub-events and schedule them too?? Here comes the Event-Wise
            Hosting. Here you can schedule sub-events like Haldi, Mehendi,
            Shadi, Reception and etcetera in your main event of wedding.
          </p>
        </div>
      </div>
      <div
        style={{
          // backgroundColor: '#2c2d52',
          background:
            "linear-gradient(180deg, rgba(214,131,255,1) 0%, rgba(255,138,215,1) 55%, rgba(255,174,230,1) 100%)",
          borderRadius: 35,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          //   justifyContent: "space-around",
          // marginBottom: 20,
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        <h2 className="horizontalHeading">Multiple Account</h2>
        <img
          // src={require('../../Assets/Images/Multiple-User.webp')}
          src={require("../../Assets/Images/mulAcc.webp")}
          alt="Multiple users in huming"
          title="A lot of users uses multiple accounts and we at huming understand that and we are working towards introducing multiple-users in single app with single mobile number."
          style={{
            width: "80%",
            zIndex: 4,
            borderRadius: 35,
          }}
          loading="lazy"
        ></img>
        <p className="paraStyle">
          This will also going to add more flexibility...You will be able to
          maintain multiple accounts simultaneously.You can organize different
          lives with different people as you want with this feature.
        </p>
      </div>
    </>
  );
};

export default PortraitTiles;
