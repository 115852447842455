import React from "react";
import HumingLogo from "../../../Assets/SVG/HumingWhiteLogo.svg";
import "../../../Styles/closing-points.css";
const ClosingPoints = () => {
  const pointersHeadingCss = {
    color: "white",
    fontSize: "3.8vw",
    width: "100%",
    marginTop: 30,
    fontFamily: "Concord",
    fontWeight: "600",
    color: "black",
  };
  const pointersSubText = {
    color: "white",
    fontSize: "2.2vw",
    width: "100%",
    marginLeft: "7vw",
    marginTop: 10,
    fontFamily: "Rubik",
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // background:
        //   "radial-gradient(rgba(200,200,50,1) 20px, rgba(0,0,0,1) 150%)",
        borderRadius: 30,
        marginTop: 30,
        marginBottom: 30,
        marginLeft: "10vw",
        marginRight: "10vw",
        // width: "60%",
      }}
    >
      <div className="note-text" style={{}}>
        Did you notice that there is no Sharing part involved in above user
        experience! That is the part which we take care of.
      </div>
      <div
        className="note-text"
        style={{
          paddingTop: 50,
          paddingBottom: 50,
        }}
      >
        None of that - creating folders, groups, selecting and curating photos,
        there is a limit on selection so repeat the selection for 1000 times,
        blah blah blah!!!
      </div>

      <img
        src={HumingLogo}
        alt="Huming's white full word Logo"
        title="Represents the subtle and convenient User experience."
        style={{
          width: "50vw",
        }}
        loading="lazy"
      />

      <div
        style={{
          color: "black",
          fontSize: "6vw",
          width: "100%",
          fontFamily: "FuturaLondon",
          fontWeight: "600",
          paddingTop: 50,
          paddingBottom: 50,
        }}
        className="roboto"
      >
        Effortless Photo Sharing for Your Adventures
      </div>
      <div className="give-it-a-go-text ">
        Still, want to spend hours sharing your memories after trips and
        events?? Just letting you know that you don't have to...
        <br />
        <br />
        Give us a try, we have just started and we are getting better and better
        day by day... <br />
        This makes us the only platform that solves this problem, we faced it
        and we understand it
      </div>

      <div className="pointers-title ">How Huming will change your life:</div>
      <div className="pointers-heading-text">
        {`1) You will never miss a memory:`}
      </div>
      <div className="pointers-sub-text">
        Automatically share photos from all your adventures.
      </div>
      <div className="pointers-heading-text">
        {`2) You will Save time and effort:`}
      </div>
      <div className="pointers-sub-text">
        Ditch the hassle of manual sharing and selection.
      </div>
      <div className="pointers-heading-text">
        {`3) There will be Effortless organization:`}
      </div>
      <div className="pointers-sub-text">
        Photos are automatically organized by trip/event for easy browsing.
      </div>
      <div className="pointers-heading-text">
        {`4) It is Secure and private:`}
      </div>
      <div className="pointers-sub-text">
        Enjoy peace of mind with secure storage and customizable privacy
        settings.
      </div>
      <div className="pointers-heading-text" style={pointersHeadingCss}>
        {`5) Perfect for all occasions:`}
      </div>
      <div className="pointers-sub-text">
        From travel adventures to birthday parties, Huming captures it all!
      </div>
      <div
        className="roboto"
        style={{
          color: "white",
          marginTop: 50,
          fontSize: "5vw",
        }}
      >
        #JoinThePre-SharingEra
      </div>
    </div>
  );
};

export default ClosingPoints;
