import React, { useState } from "react";
import "../Styles/footer-styles.css";
import HumingLogo from "../Assets/SVG/HumingLogoFooter.svg";
import ComingSoonModal from "./ComingSoonModal";

const Footer = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    // here is where we will declare lottie animation
    // "animation" is what we imported before animationData: animation,

    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [openModal, setOpenModal] = useState(false);
  return (
    <footer className="footer">
      <ComingSoonModal
        handleClose={() => {
          setOpenModal(false);
        }}
        open={openModal}
      />
      <div className="footer__addr">
        <img
          src={HumingLogo}
          alt="Huming's white full word Logo"
          title="Represents the subtle and convenient User experience."
          style={{
            height: "48%",
            width: "48%",
          }}
          loading="lazy"
        />

        {/* <h3>Contact:</h3> */}

        <address>
          {/* Keshav Nagar, University Road, Udaipur, Rajasthan, 313001
          <br /> */}
          <a className="footer__btn" href="/contact">
            {`Contact Info`}
          </a>
        </address>
      </div>

      <ul className="footer__nav">
        <li className="nav__item">
          <h3 className="nav__title">Join Us</h3>

          <ul className="nav__ul">
            <li>
              <a
                href="https://www.instagram.com/huming.app/"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </li>

            <li>
              <a
                href="https://www.youtube.com/@Huming.official"
                target="_blank"
                rel="noreferrer"
              >
                YouTube
              </a>
            </li>

            <li>
              <a
                href="https://www.linkedin.com/company/huming-app/"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/huming.official"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            </li>
          </ul>
        </li>

        <li className="nav__item nav__item--extra">
          <h2 className="nav__title">Download</h2>

          <ul className="nav__ul nav__ul--extra">
            <li>
              <a
                href="https://apps.apple.com/us/app/huming/id6462796387"
                target="_blank"
                rel="noreferrer"
              >
                iOS
              </a>
            </li>

            <li>
              <a
                href="https://play.google.com/store/apps/details?id=com.huming"
                target="_blank"
                rel="noreferrer"
              >
                Android
              </a>
            </li>
          </ul>
        </li>

        <li className="nav__item">
          <h2 className="nav__title">Legal</h2>

          <ul className="nav__ul">
            <li>
              <a href="/privacy">Privacy Policy</a>
            </li>

            <li>
              <a href="#">Sitemap</a>
            </li>
          </ul>
        </li>
        <li className="nav__item">
          <h2 className="nav__title">Know more</h2>

          <ul className="nav__ul">
            <li>
              <a href="http://huming.app/future">Future</a>
            </li>

            <li>
              <a href="https://huming.app/about">Who Are We</a>
            </li>
          </ul>
        </li>
      </ul>

      <div className="legal">
        {/* <p>&copy; 2023 Huming Infotech Private Limited. All rights reserved.</p> */}
        <p></p>
        <div className="legal__links">
          <span>
            Made with <span className="heart">♥</span> remotely from Udaipur,
            Mumbai, Gurgaon
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
