import Modal from "@mui/material/Modal";
import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
function ComingSoonModal({ open, handleClose }) {
  //   const [open, setOpen] = React.useState(false);
  //   const handleOpen = () => setOpen(true);
  //   const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Coming Soon
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          It will be worth your time.
        </Typography>
      </Box>
    </Modal>
  );
}

export default ComingSoonModal;
