import React from "react";
import "../Styles/youtube-floating-button.css";
import YoutubeLogo from "../Assets/SVG/youtube.svg";
export default function YouTubeFloatingButton() {
  const onClickYouTubeButton = () => {};
  return (
    <a
      href="https://www.youtube.com/watch?v=Xe74lXynSlU"
      target="_blank"
      rel="noreferrer"
    >
      <button className="button" onClick={onClickYouTubeButton}>
        <p className="button__text">
          <span style={{ "--index": 0 }}>W</span>
          <span style={{ "--index": 1 }}>A</span>
          <span style={{ "--index": 2 }}>T</span>
          <span style={{ "--index": 3 }}>C</span>
          <span style={{ "--index": 4 }}>H</span>
          <span style={{ "--index": 5 }}> </span>
          <span style={{ "--index": 6 }}>O</span>
          <span style={{ "--index": 7 }}>U</span>
          <span style={{ "--index": 8 }}>R</span>
          <span style={{ "--index": 9 }}> </span>
          <span style={{ "--index": 10 }}>V</span>
          <span style={{ "--index": 11 }}>I</span>
          <span style={{ "--index": 12 }}>D</span>
          <span style={{ "--index": 13 }}>E</span>
          <span style={{ "--index": 14 }}>O</span>
          {/* <span style={{ "--index": 15 }}>T</span>
        <span style={{ "--index": 16 }}>O</span>
        <span style={{ "--index": 17 }}>N</span> */}
        </p>

        <div className="button__circle">
          <img
            src={YoutubeLogo}
            alt="Huming's white full word Logo"
            title="Represents the subtle and convenient User experience."
            style={{
              height: "48%",
              width: "48%",
            }}
            loading="lazy"
          />
        </div>
      </button>
    </a>
  );
}
