import React from "react";
import BackgroundVideo from "./BackgroundVideo";
import Header from "./Header";
import "../Styles/whoarewe-styles.css";
import HeaderDrawer from "./HeaderDrawer";
import { useDocumentTitle } from "../Hooks/changeTitleHook";

const TextOverVideoComp = () => {
  return (
    <div
      style={{
        bottom: -1,
        left: 0,
        position: "absolute",
        width: "100%",
        height: "100%",
        background: "linear-gradient(rgb(0,0,0,0), rgb(0,0,0,1))",
      }}
    >
      <div className="futurePara">
        <strong>
          {` We want this to be a platform where you share the precious memories to
          your loved ones in a most-simplest, most-convenient, non-complex,
          hassle-free, "fight with your loved ones free" way possible, `}
        </strong>
        without sacrificing the safety, security and freedom to choose, and
        simultaneously organizing all the memories so that you don't loose them
        and there gist/value. we have aggressively worked on and still working
        on giving you the best user experience.
      </div>
      <div
        style={{
          bottom: "5%",
          left: 30,
          position: "absolute",
          zIndex: 4,
        }}
      >
        <div className="futureHeading">Vision</div>
      </div>
    </div>
  );
};

function SectionComponent({
  srcURL,
  topic,
  content,
  isPicturePlacementLeft,
  extraTextLeftPadding,
  alt,
  title,
}) {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "black",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        className={
          isPicturePlacementLeft
            ? "flex-row section-container"
            : "flex-row-reverse section-container"
        }
      >
        <img
          className="section-image"
          src={srcURL}
          alt={alt}
          title={title}
          loading="lazy"
        />
        <div className="section-heading">
          {topic}
          <div className="section-para">{content}</div>
        </div>
      </div>
    </div>
  );
}

export default function WhoAreWe() {
  useDocumentTitle(
    "Vision, Core Driving Values, through which we created the world's most convenient photo sharing app is created."
  );
  document
    .querySelector('meta[name="description"]')
    .setAttribute(
      "content",
      "At Huming our core values are driven by problem solving urge of each team member of huming, from starting we worked towards creating the most user friendly and least complex photo and video sharing app which does everything automatically but give you control of the automation at each stage to ensure best user experience."
    );

  return (
    <div
      style={{
        backgroundColor: "black",
      }}
    >
      <Header inFocus={2} strokeColor={"white"} screenName="WhoAreWe" />
      <HeaderDrawer inFocus={2} screenName="WhoAreWe" />
      <BackgroundVideo
        srcPath={require("../Assets/Videos/AboutUs.mp4")}
        thumbnail={require("../Assets/Videos/AboutUs.webp")}
        childComponent={TextOverVideoComp}
        height={"100vh"}
      />

      <SectionComponent
        srcURL={require("../Assets/Images/AboutUs_Idea.webp")}
        topic={"How it struck?"}
        content={`We just came from a trip, all tired-up and one of our friend said
        "plz send all the pictures please!!!!". Everybody agreed and said
        "yes please!! do send all the pictures". After one week everybody is
        saying please send pictures but nobody was sending them. At that
        point it struck that WHY NOT!!...(BTW!! The pictures are not yet sent)`}
        isPicturePlacementLeft={true}
        extraTextLeftPadding={0}
        alt="Ideation of Huming"
        title="This section explains how the founders of Huming ideated that how they got to the problem and solution"
      />
      <SectionComponent
        srcURL={require("../Assets/Images/ThinkingMonk.webp")}
        topic={"Coming up with the solution"}
        content={`We did some research that are there any solutions for this,
         addressing the problem of manual/hectic/effortful most importantly post-sharing
         memories and there were none. Now we knew what's the target problem and
        created a solution, took loads of suggestions, re-did and added more features. 
        And hence this platform.`}
        alt="Solution of Huming"
        title="This section explains how the founders of Huming got to the final solution of huming that hpw it should work and help the users solve this daily life problem"
        isPicturePlacementLeft={false}
        extraTextLeftPadding={0}
      />
      <SectionComponent
        srcURL={require("../Assets/Images/Team.webp")}
        topic={"Our Team"}
        content={`If we have to define our team in one line then it will
         be that we are sucker for problems and thrives to solve them. 
         This team faced loads of hiccups, difficulties in between but
          whenever we were in a mess everybody was like
           "nice!! today will be fun..."`}
        alt="Team of Huming"
        title="This section explains how the whole team of huming worked together, day and night and made this product of huming possible in which they achieved the automation sharing of photos and videos"
        isPicturePlacementLeft={true}
        extraTextLeftPadding={0}
      />

      <div
        style={{
          fontFamily: "BebasNeue",
          fontSize: 33,
          color: "#ffc200",
          fontWeight: "300",
          alignSelf: "center",
          width: "80%",
          marginLeft: "10%",
          textAlign: "center",
          paddingBottom: 30,
          backgroundColor: "black",
        }}
      >
        {`We are very keen and focused in providing the best user experience possible in terms of safety, security, convenience of use and smooth user interface.`}
      </div>
    </div>
  );
}
