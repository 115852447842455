import React, { useState } from "react";
import { WavyLink } from "react-wavy-transitions";
import "../Styles/header-styles.css";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import { Box, Button, List, ListItem } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import HumingLogo from "../Assets/SVG/HumingLogo.svg";
import HumingWhiteLogo from "../Assets/SVG/HumingWhiteLogo.svg";
export default function HeaderDrawer({ inFocus, screenName }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const openDrawer = () => {
    setIsDrawerOpen(true);
  };
  const closeDrawer = () => (event) => {
    setIsDrawerOpen(false);
  };

  return (
    <div className="drawer-base">
      <Button
        style={{
          position: "absolute",
          color: "white",
          zIndex: 10,
          marginTop: 20,
          marginLeft: 20,
        }}
        onClick={openDrawer}
        aria-valuetext="Drawer button for home page, future page and about page"
      >
        <MenuIcon fontSize="large" />
      </Button>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={closeDrawer()}
        onKeyDown={closeDrawer()}
      >
        <Box sx={{ width: 250 }}>
          <List>
            <ListItem key="menu">
              <Button onClick={closeDrawer()} aria-valuetext="close the drawer">
                <ArrowBackIosIcon fontSize="large" sx={{ color: "black" }} />
              </Button>
            </ListItem>
            <ListItem
              key="home"
              style={{
                backgroundColor: inFocus === 0 ? `black` : `white`,
              }}
            >
              <WavyLink
                to={"/"}
                children={
                  <div
                    style={{
                      color: inFocus === 0 ? `white` : `black`,
                    }}
                    className="drawer-font"
                  >
                    Home
                  </div>
                }
                color="#2f2c52"
              />
            </ListItem>
            <ListItem
              key="future"
              style={{
                backgroundColor: inFocus === 1 ? `black` : `white`,
              }}
            >
              <WavyLink
                to={"/future"}
                children={
                  <div
                    style={{
                      color: inFocus === 1 ? `white` : `black`,
                    }}
                    className="drawer-font"
                  >
                    Future
                  </div>
                }
                color="#2f2c52"
              />
            </ListItem>
            <ListItem
              key="whoarewe"
              style={{
                backgroundColor: inFocus === 2 ? `black` : `white`,
              }}
            >
              <WavyLink
                to={"/about"}
                children={
                  <div
                    style={{
                      color: inFocus === 2 ? `white` : `black`,
                    }}
                    className="drawer-font"
                  >
                    Who are we
                  </div>
                }
                color="#2f2c52"
              />
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <WavyLink
        to={"/"}
        children={
          <img
            src={screenName === "Future" ? HumingWhiteLogo : HumingLogo}
            alt="Huming's black full word Logo"
            title="Represents the subtle and convenient User experience."
            style={{
              width: "200px",
              height: "120px",
              zIndex: 10,
              position: "absolute",
              marginTop: -10,
              left: window.innerWidth / 2 - 100,
            }}
            loading="lazy"
          />
        }
        color="#2f2c52"
      />
    </div>
  );
}
