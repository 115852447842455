import React from "react";
import "./App.css";
import { WavyContainer } from "react-wavy-transitions";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import Header from "./Components/Header";
import Future from "./Components/Future";
import WhoAreWe from "./Components/WhoAreWe";
import Footer from "./Components/Footer";
import HumingPrivacyPolicy from "./Components/HumingPrivacyPolicy";
import ContactUs from "./Components/ContactUs";
import E404 from "./Components/E404";
// const Home = React.lazy(() => import("./Components/Home"));
// const Future = React.lazy(() => import("./Components/Future"));
// const WhoAreWe = React.lazy(() => import("./Components/WhoAreWe"));
// const Footer = React.lazy(() => import("./Components/Footer"));
// const HumingPrivacyPolicy = React.lazy(() =>
//   import("./Components/HumingPrivacyPolicy")
// );
function App() {
  return (
    <>
      <BrowserRouter>
        <WavyContainer />
        {/* <React.Suspense fallback={<span>Loading...</span>}> */}
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="/future" element={<Future />} />
          <Route path="/about" element={<WhoAreWe />} />
          <Route path="/privacy" element={<HumingPrivacyPolicy />} />
          <Route path="/contact" element={<ContactUs />} />

          <Route
            status={404}
            path="*"
            element={
              <>
                <Header bg={"transparent"} />
                <E404 />
              </>
            }
          />
        </Routes>
        {/* </React.Suspense> */}
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;
